.buttonsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: fit-content;
    padding: 5px;
    font-size: 30px;
}

.icon i {
    display: block;
    padding: 5px;
    margin: 0px;
    transition: ease-in-out 0.2s;
}

.icon i:hover {
    transform: scale(1.1);
    outline: none;
    text-decoration: none;
}
