body {
  margin: 0;
  font-size: 1.3em;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  overflow: hidden;
  height: 100%;
  background-color: rgb(31, 31, 31);
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://i.pinimg.com/originals/f8/10/38/f8103819da656ccffb9dd9906832a251.jpg);
}

/* @media screen and (max-device-width: 414px) {
  body {
    font-weight: 400;
    font-size: 1em;
  }
} */

a {
  color: #fff;
  outline: none;
  text-decoration: none;
}

a:visited {
  color: #fff;
}

a:active {
  color: #fff;
  outline: none;
  text-decoration: none;
}

html, body {
  position: fixed;
}
/*
html, body {
  position: relative;
  overflow: hidden;
} */

body {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
