.Donate {
    height: auto;
    transform: scale(1);
    display: block;
    width: 50%;
    max-width: 700px;
    /* margin-top:  calc(50% - 327px); */
    /* margin-top:  calc((100vh - 654px)/2); */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(40, 50, 76, 0.3);
    text-align: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.200);
}

@media screen and (max-device-width: 800px) {
    .Donate {
        width: 80%;
        /* margin: 5vh auto; */
    }
    /* .cryptowallet {
        width: 33vw !important;
    } */
}

@media screen and (max-device-width: 350px) {
    .Donate {
        width: 80%;
        /* margin: 1vh auto; */
    }
    /* .cryptowallet {
        width: 33vw !important;
    } */
}

.iframe {
    padding: 15px 0 15px;
}

form {
    /* display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center; */
    margin: auto;
    /* width: fit-content; */
}

.frotmControlWrapper {
    width: 250px;
    margin: auto;
}

.radioButton {
    vertical-align: middle;
    height: 50px;
    position: relative;
}

.field {
    display: block;
    width: auto;
    margin: 30px;
}

.field input {
    width: fit-content;
}

.mainBlock {
    margin: auto;
    /* max-height: 80vh; */
}

.avatarWrapper {
    margin: auto;
    margin-bottom: 10px;
    user-select: none;
}

.avatar {
    margin: auto;
    display: block;
    border-radius: 160px;
    width: 30%;
    max-width: 100px;
    min-width: 10px;
}

.back {
    float: left;
    top: 0px;
    left: 0px;
    position: absolute;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.03);
    width: 30px;
    height: 100%;
    z-index: 1000;
}

.arrow {
    transform: rotate(135deg);
    width: 11px;
    height: 11px;
    border-radius: 2px;
    border-bottom: 1.5px solid #ccc;
    border-right: 1.5px solid #ccc;
    margin: 275px 10px;
}

.disabledWallet {
    width: 40px;
    filter: grayscale(100%);
}

.enabledWallet {
    width: 40px;
}

.wallet {
    display: flex;
    flex-direction: row !important;
}

.wallet :nth-child(even) {
    margin: 0px;
}

.FormControlLabel {
    margin: 5px;
}

.FormControl {
    display: block;
    width: 100%;
    margin: 10px;
}

.Donate::-webkit-scrollbar {
    padding-bottom: 100px;
    margin-bottom: 100px;
    overflow-x: auto;
}

.blockCryptography {
    overflow: hidden;
    margin: 20px auto 5px auto;
    user-select: none;
    border-radius: 3px;
    /* width: 80%; */
}

.cryptowalletWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px auto;
    width: 80%;
    max-width: 380px;
}

.walletName {
    text-align: center;
    display: inline-block;
    font-family: Monospace;
    font-size: 16px;
    align-self: center;
}

.cryptowallet {
    margin: 5px 0 5px 10px;
    text-align: center;
    display: inline-block;
    font-family: Monospace;
    font-size: 16px;
    overflow: hidden;
    padding: 3px;
    border-radius: 3px;
    background-color: #00000030;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: all;
}

.copyIcon {
    align-self: center;
    margin: 5px;
    opacity: 0.6;
    margin: 5px 0 5px 10px;
    padding: 3px;
    border-radius: 3px;
    background-color: #00000030
}
