.Main {
    transform: scale(1);
    display: block;
    width: 50%;
    max-width: 700px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: 5px;
    background-color: rgba(40, 50, 76, 0.3);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.200);
    text-align: center;
    color: #eee;
}

@media screen and (max-device-width: 920px) {
    .Main {
        width: 80%;
    }
}

/* .mainBlock {
    margin: auto;
} */

.avatarWrapper {
    margin: auto;
    user-select: none;
}

.avatar, .avatar img {
    margin: auto;
    display: block;
    border-radius: 160px;
    max-width: 100px;
    min-width: 10px;
}

.textWrapper {
    margin: 10px;
}

.donateWrapper {
    user-select: none;
    margin: 15px auto -10px;
    width: fit-content;
    padding: 4px 10px 8px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.07);
}

.donateWrapper i {
    font-size: 25px;
}

.donateWrapper a {
    font-size: 20px;
    font-weight: lighter;
    vertical-align: middle;
}

.projectsWrapper {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    margin: 10px auto 0px;
    user-select: none;
}

.myProjectsTitle {
    font-size: 0.7em;
    color: #ccc;
}

.project {
    margin: 10px 10px 0px 10px;
}

.projectIcon img {
    width: 30px;
    border-radius: 30px;
}
